<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else class="d-flex justify-content-center">
      <mapOrderJournals
        :history="history"
        :order="order"
        :orderHistory="orderHistory"
      />
      <panelInformation
        :order="order"
        :orderHistory="orderHistory"
        @markerShow="markerShow"
      />
    </div>
  </div>
</template>

<script>
import panelInformation from "@/views/component/Map/orderAndJournals/panelInformation.vue";
import mapOrderJournals from "@/views/component/Map/orderAndJournals/map.vue";
export default {
  components: {
    panelInformation,
    mapOrderJournals,
  },
  data() {
    return {
      id: this.$route.query.map,
      showPreloader: false,
      order: [],
      orderHistory: [],
      history: null,
    };
  },
  created() {
    this.infoOrderData();
    this.$store.commit("pageData/setdataCount", null);
  },
  methods: {
    infoOrderData() {
      this.showPreloader = true;
      this.$http.get(`orders/${this.id}/edit`).then((res) => {
        this.order = res.data;
        console.log(res.data);

        this.showPreloader = false;
      });
      this.$http.get(`orders/${this.id}/history`).then((res) => {
        this.orderHistory = res.data;
      });
    },
    markerShow(history) {
      this.history = history;
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.$http.get(`orders/${this.id}/edit`).then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
